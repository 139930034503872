const btnElement = document.getElementById("menu-button")!;
const btnBackElement = document.querySelector(".nav-bar__back-button")!;
const navBarElement = document.querySelector(".nav-bar")!;

const visible = "nav-bar--visible";
const hidden = "nav-bar--hidden";

btnElement.addEventListener("click", () => {
  navBarElement.classList.remove(hidden);
  navBarElement.classList.add(visible);
});

btnBackElement.addEventListener("click", () => {
  navBarElement.classList.remove(visible);
  navBarElement.classList.add(hidden);
});
